import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RisultatoDTO } from '../../../core/models';
import { PaymentConfiguration } from '../models';

export const GPECBIE = 'application/csh/cishoppo/paypal/1.0/getPayPalEsercenteConfByIdEsercente';
export const IPEC = 'application/csh/cishoppo/paypal/1.0/insertPayPalEsercenteConf';
export const UPEC = 'application/csh/cishoppo/paypal/1.0/updatePayPalEsercenteConf';

@Injectable({
  providedIn: 'root'
})
export class PaymentConfigurationService {

  constructor(
    private httpClient: HttpClient) { }

  getPayPalEsercenteConfByIdEsercente(n_id_esercente: number): Observable<RisultatoDTO<PaymentConfiguration>> {
    let parametri = '?n_id_esercente=' + n_id_esercente;
    return this.httpClient.get<RisultatoDTO<PaymentConfiguration>>(`${environment.apiBaseUrl}${GPECBIE}${parametri}`);
    /*  return this.httpClient.get<RisultatoDTO<PaymentConfiguration>>('assets/mock/paymentConfiguration.json'); */
  }

  insertPayPalEsercenteConf(paymentConfiguration: PaymentConfiguration): Observable<RisultatoDTO<string>> {
    return this.httpClient.put<RisultatoDTO<string>>(`${environment.apiBaseUrl}${IPEC}`, paymentConfiguration);
  }

  updatePayPalEsercenteConf(paymentConfiguration: PaymentConfiguration): Observable<RisultatoDTO<string>> {
    return this.httpClient.post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${UPEC}`, paymentConfiguration);
  }

}
