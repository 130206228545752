import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RisultatoDTO } from '../../../core/models';
import { Observable } from 'rxjs';
import { Categoria, City, Giorno, GeocodeRequest, FilterEsercenteRequest, UtenzaRicercaEsercente, FilterEsercentiRequest, FilterEsercentiResponse, AttivitaCommercialeComplete, AttivitaCommercialeCompleteDettaglio, ListaImmaginiRequestUpdate, FasceOrarieUpdateRequest, AttivitaCommercialeUpdateRequest, Esercente, AttivitaCommercialeLight, AttivitaCommercialeRequestApprovazione } from '../models';
import { environment } from '../../../../environments/environment';
import { Prefisso } from '../../utenza/models/prefisso';
import { ApprovaRifiutaNegozioRequest } from '../models/approva-rifiuta-negozio-request';
import { map } from 'rxjs/operators';

export const GCAT = 'application/csh/cishoppo/categoria/1.0/getAllCategorie';
export const GPREF = 'application/csh/cishoppo/prefissinazioni/1.0/getAllPrefissiNazioni';
export const GCIT = 'application/csh/cishoppo/citta/1.0/getAllCity';
export const GAW = 'application/csh/cishoppo/settimana/1.0/getAllWeek';
export const AE = 'application/csh/cishoppo/esercente/1.0/accreditaEsercente';
export const AEL = 'application/csh/cishoppo/esercente/1.0/accreditaEsercenteLight';
export const MAP_GEO = 'https://maps.googleapis.com/maps/api/geocode/json';
export const GEF = 'application/csh/cishoppo/service/1.0/getEsercentiByFiltersPaginatedWeb';
export const SULCARP = 'application/csh/cishoppo/service/1.0/searchUsersLikeClaimAndRolePaginated';
export const GDE = 'application/csh/cishoppo/esercente/1.0/dettaglioEsercente';
export const ULFO = 'application/csh/cishoppo/fasciaOrarie/1.0/updateListaFasciaOrarie';
export const UIE = 'application/csh/cishoppo/immagine/1.0/updateImmaginiEsercente';
export const UE = 'application/csh/cishoppo/esercente/1.0/updateEsercente';
export const GNBI = 'application/csh/cishoppo/esercente/1.0/getNegoziByIdUser';
export const DE = 'application/csh/cishoppo/esercente/1.0/disableEsercente';
export const TANBI = 'application/csh/cishoppo/esercente/1.0/tryApprovaNegozioById';
export const RE = 'application/csh/cishoppo/esercente/1.0/rifiutaEsercente';
export const ACE = 'application/csh/cishoppo/esercente/1.0/accettaEsercente';

@Injectable({
    providedIn: 'root'
})
export class NegozioService {

    constructor(
        private httpClient: HttpClient) { }

    getAllCategorie(): Observable<RisultatoDTO<Array<Categoria>>> {
        return this.httpClient.get<RisultatoDTO<Array<Categoria>>>(`${environment.apiBaseUrl}${GCAT}`);
    }

    getAllCity(): Observable<RisultatoDTO<Array<City>>> {
        return this.httpClient.get<RisultatoDTO<Array<City>>>(`${environment.apiBaseUrl}${GCIT}`);
    }

    getAllWeek(): Observable<RisultatoDTO<Array<Giorno>>> {
        return this.httpClient.get<RisultatoDTO<Array<Giorno>>>(`${environment.apiBaseUrl}${GAW}`);
    }

    getAllPrefix(): Observable<RisultatoDTO<Array<Prefisso>>> {
        return this.httpClient.get<RisultatoDTO<Array<Prefisso>>>(`${environment.apiBaseUrl}${GPREF}`);
    }

    accreditaEsercente(attivita_commerciale_complete: AttivitaCommercialeComplete): Observable<RisultatoDTO<string>> {
        return this.httpClient.put<RisultatoDTO<string>>(`${environment.apiBaseUrl}${AE}`, attivita_commerciale_complete);
    }

    accreditaEsercenteLight(attivitaCommercialeLight: AttivitaCommercialeLight): Observable<RisultatoDTO<string>> {
        return this.httpClient.put<RisultatoDTO<string>>(`${environment.apiBaseUrl}${AEL}`, attivitaCommercialeLight);
    }

    searchUsersLikeClaimAndRolePaginated(filterEsercenteRequest: FilterEsercenteRequest): Observable<RisultatoDTO<UtenzaRicercaEsercente>> {
        return this.httpClient.post<RisultatoDTO<UtenzaRicercaEsercente>>(`${environment.apiBaseUrl}${SULCARP}`, filterEsercenteRequest);
    }

    getLatAndLong(geocode_request: GeocodeRequest) {
        let indirizzo = geocode_request.t_indirizzo + ',';
        let civico = geocode_request.t_civico + ',';
        let city = geocode_request.t_city + ',';
        let cap = geocode_request.t_cap + ',';
        let provincia = geocode_request.t_provicia;
        let parametri = "?address=" + indirizzo + civico + city + cap + provincia + '&key=' + environment.apiKeyGM;
        return this.httpClient.get(MAP_GEO + parametri);
    }

    getEsercentiByFiltersPaginated(filterEsercentiRequest: FilterEsercentiRequest): Observable<RisultatoDTO<FilterEsercentiResponse>> {
        return this.httpClient.post<RisultatoDTO<FilterEsercentiResponse>>(`${environment.apiBaseUrl}${GEF}`, filterEsercentiRequest);
    }

    getDettaglioAttivitaCommerciale(n_id_esercente: number): Observable<RisultatoDTO<AttivitaCommercialeCompleteDettaglio>> {
        let parametri = '?id_esercente=' + n_id_esercente;
        return this.httpClient.get<RisultatoDTO<AttivitaCommercialeCompleteDettaglio>>(`${environment.apiBaseUrl}${GDE}${parametri}`);
    }

    updateEsercente(attivitaCommercialeUpdateRequest: AttivitaCommercialeUpdateRequest): Observable<RisultatoDTO<string>> {
        return this.httpClient.post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${UE}`, attivitaCommercialeUpdateRequest);
    }

    updateImmaginiEsercente(listaImmaginiRequestUpdate: ListaImmaginiRequestUpdate): Observable<RisultatoDTO<string>> {
        return this.httpClient.post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${UIE}`, listaImmaginiRequestUpdate);
    }

    updateListaFasceOrarie(fasceOrarieUpdateRequest: FasceOrarieUpdateRequest): Observable<RisultatoDTO<string>> {
        return this.httpClient.post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${ULFO}`, fasceOrarieUpdateRequest);
    }

    getNegoziByIdUser(t_id_user: string): Observable<RisultatoDTO<Array<Esercente>>> {
        let parametri = '?t_id_user=' + t_id_user;
        return this.httpClient.get<RisultatoDTO<Array<Esercente>>>(`${environment.apiBaseUrl}${GNBI}${parametri}`);
    }

    disableEsercente(n_id_esercente: number): Observable<RisultatoDTO<string>> {
        let parametri = '?n_id=' + n_id_esercente;
        return this.httpClient.delete<RisultatoDTO<string>>(`${environment.apiBaseUrl}${DE}${parametri}`);
    }

    tryApprovaNegozioById(request_approvazione: AttivitaCommercialeRequestApprovazione): Observable<RisultatoDTO<string>> {
        return this.httpClient.put<RisultatoDTO<string>>(`${environment.apiBaseUrl}${TANBI}`, request_approvazione);
    }

    approvaNegozio(approvaRifiutaNegozioRequest: ApprovaRifiutaNegozioRequest): Observable<RisultatoDTO<string>> {
        return this.httpClient.post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${ACE}`, approvaRifiutaNegozioRequest);
    }

    respingiNegozio(approvaRifiutaNegozioRequest: ApprovaRifiutaNegozioRequest): Observable<RisultatoDTO<string>> {
        return this.httpClient.post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${RE}`, approvaRifiutaNegozioRequest);
    }

}
