import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RisultatoDTO, Registrazione } from '../../../core/models';
import { environment } from '../../../../environments/environment';
import { FilterUtenzaRequest, UtenzaRicercaResponse, ModificaDatiUtenza, CambiaPassword, DettaglioUtente } from '../models';
import { Prefisso } from '../../negozio/models/prefisso';

//PATH SERVIZI
export const RU = 'application/csh/cishoppo/service/1.0/createNewUtenza';
export const DU = 'application/csh/cishoppo/service/1.0/deleteUtenza';
export const SULCARP = 'application/csh/cishoppo/service/1.0/searchUsersLikeClaimAndRolePaginated';
export const GPREF = 'application/csh/cishoppo/prefissinazioni/1.0/getAllPrefissiNazioni';
export const UUD = 'application/csh/cishoppo/service/1.0/updateUserData';
export const GDU = 'application/csh/cishoppo/service/1.0/getDettaglioUtenza';
export const CP = '/application/csh/cishoppo/service/1.0/changeUserPassword';

@Injectable({
  providedIn: 'root'
})
export class UtenzaService {

  constructor(
    private httpClient: HttpClient) { }

  registraUtenza(utenza: Registrazione): Observable<RisultatoDTO<string>> {
    return this.httpClient
      .put<RisultatoDTO<string>>(`${environment.apiBaseUrl}${RU}`, utenza);
  }

  deleteUtenza(t_id_user: string): Observable<RisultatoDTO<string>> {
    let parametri = '?t_id_user=' + t_id_user;
    return this.httpClient
      .delete<RisultatoDTO<string>>(`${environment.apiBaseUrl}${DU}${parametri}`);
  }

  updateUserData(modificaDatiUtenza: ModificaDatiUtenza): Observable<RisultatoDTO<string>> {
    return this.httpClient
      .post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${UUD}`, modificaDatiUtenza);
  }

  getDettaglioUtenza(t_id_user: string): Observable<RisultatoDTO<DettaglioUtente>> {
    let parametri = '?t_id_user=' + t_id_user;
    return this.httpClient
      .get<RisultatoDTO<DettaglioUtente>>(`${environment.apiBaseUrl}${GDU}${parametri}`);
  } 

  cambiaPassword(cambiaPassword: CambiaPassword): Observable<RisultatoDTO<string>> {
    return this.httpClient.post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${CP}`, cambiaPassword)
  }

  searchUsersLikeClaimAndRolePaginated(filterUtenzaRequest: FilterUtenzaRequest): Observable<RisultatoDTO<UtenzaRicercaResponse>> {
    return this.httpClient.post<RisultatoDTO<UtenzaRicercaResponse>>(`${environment.apiBaseUrl}${SULCARP}`, filterUtenzaRequest);
  }

  getAllPrefix(): Observable<RisultatoDTO<Array<Prefisso>>> {
    return this.httpClient.get<RisultatoDTO<Array<Prefisso>>>(`${environment.apiBaseUrl}${GPREF}`);
  }

}
